import React, { useEffect, useState, useRef } from 'react';
import { translateText } from './translate';
import { franc } from 'franc';
import Typed from 'typed.js';

const ExperienceList = ({ experiences }) => {
  const [translatedExperiences, setTranslatedExperiences] = useState([]);
  const [loadingTranslations, setLoadingTranslations] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [inputLanguage, setInputLanguage] = useState('en'); // Default to English
  const [animationActive, setAnimationActive] = useState(true); // Track if the animation is active
  const inputRef = useRef(null);
  const typedInstance = useRef(null); // Ref for the Typed instance
  
  const demoSearchTerms = [
    "fish don't eat good",
    "router problem",
    "streaming issues"
  ];

  const supportedLanguages = ['en', 'nl', 'de', 'es'];

  // Function to detect the input language
  const detectLanguage = (text) => {
    const detectedLang = franc(text);
    switch (detectedLang) {
      case 'nld': return 'nl';
      case 'deu': return 'de';
      case 'spa': return 'es';
      default: return 'en';
    }
  };

  // Function to translate the input value into all supported languages
  const translateToAllLanguages = async (text) => {
    try {
      const translations = await Promise.all(
        supportedLanguages.map(async (lang) => {
          const translatedText = await translateText(text, lang);
          return { language: lang, text: translatedText };
        })
      );
      console.log("Translations:", translations); // Debugging log
      return translations;
    } catch (error) {
      console.error('Error translating to all languages:', error);
      return supportedLanguages.map((lang) => ({ language: lang, text }));
    }
  };

  const translateExperiencesToUserLanguage = async (experiences, userLanguage) => {
    try {
      const translatedExps = await Promise.all(
        experiences.map(async (experience) => {
          const translatedDescription = await translateText(experience.description, userLanguage);
          const translatedWhatWorked = await translateText(experience.whatWorked, userLanguage);
          const translatedWhatDidNotWork = await translateText(experience.whatDidNotWork, userLanguage);

          return {
            ...experience,
            description: translatedDescription,
            whatWorked: translatedWhatWorked,
            whatDidNotWork: translatedWhatDidNotWork,
          };
        })
      );

      return translatedExps;
    } catch (error) {
      console.error('Error translating experiences:', error);
      return experiences; // If translation fails, return the original experiences
    }
  };

  const handleSearchChange = async (e) => {
    const value = e.target.value;
    setSearchTerm(value);

    // Stop the animation if the user starts typing
    if (animationActive) {
      setAnimationActive(false);
      if (typedInstance.current) {
        typedInstance.current.stop(); // Stop Typed.js
      }
    }

    if (value.trim() === '') {
      setTranslatedExperiences([]);
      return;
    }

    // Detect language of the input
    const detectedLang = detectLanguage(value);
    setInputLanguage(detectedLang);

    // Fetch translations of the search term in all supported languages
    const translations = await translateToAllLanguages(value);
    const searchTerms = translations.map(t => t.text.toLowerCase());

    // Filter experiences based on translated search terms in their original language
    const filteredResults = experiences.filter(experience => 
      searchTerms.some(term =>
        experience.description.toLowerCase().includes(term) ||
        experience.whatWorked.toLowerCase().includes(term) ||
        experience.whatDidNotWork.toLowerCase().includes(term)
      )
    );

    console.log("Filtered Results:", filteredResults); // Debugging log

    // Translate filtered results to the user's language
    const userLanguage = detectedLang;
    const translatedResults = await translateExperiencesToUserLanguage(filteredResults, userLanguage);
    setTranslatedExperiences(translatedResults);
  };

  useEffect(() => {
    if (animationActive) {
      // Set up Typed.js instance for the automatic demonstration
      typedInstance.current = new Typed(inputRef.current, {
        strings: demoSearchTerms,
        typeSpeed: 50,
        backSpeed: 30,
        backDelay: 2000,
        startDelay: 500,
        loop: false,
        onComplete: () => {
          // Automatically perform search when a term completes
          handleSearchChange({ target: { value: demoSearchTerms[typedInstance.current.arrayPos] } });
        },
      });
    }

    // Clean up Typed.js instance on component unmount
    return () => {
      if (typedInstance.current) {
        typedInstance.current.destroy();
      }
    };
  }, [animationActive]);

  return (
    <div className="search-container">
      <input
        ref={inputRef}
        className="search-bar"
        type="text"
        placeholder="Enter your issue to search..."
        value={searchTerm}
        onChange={handleSearchChange}
        onFocus={() => setAnimationActive(false)} // Stop animation if user interacts with the input
      />
      {loadingTranslations ? (
        <p>Loading translations...</p>
      ) : (
        searchTerm && translatedExperiences.length > 0 && (
          <div className="summary-container">
            {translatedExperiences.some(exp => exp.whatWorked) && (
              <div className="worked-summary">
                <h2>What Worked</h2>
                {translatedExperiences.map((experience, index) => (
                  experience.whatWorked ? <p key={index}>{experience.whatWorked}</p> : null
                ))}
              </div>
            )}

            {translatedExperiences.some(exp => exp.whatDidNotWork) && (
              <div className="didnt-work-summary">
                <h2>What Didn't Work</h2>
                {translatedExperiences.map((experience, index) => (
                  experience.whatDidNotWork ? <p key={index}>{experience.whatDidNotWork}</p> : null
                ))}
              </div>
            )}
          </div>
        )
      )}
    </div>
  );
};

export default ExperienceList;
